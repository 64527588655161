import React, { useEffect, useState } from "react";
import Paging from "../../component/Paging";
import { singleTable } from "./constants";
import { useNavigate, useParams } from "react-router-dom";
import { get, put } from "../../../services/apiHelper";
const TableRow = ({ data }) => {
  return (
    <tr>
      <td>{data.column1}</td>
      <td>{data.column2}</td>
      <td>{data.column3}</td>
      <td>{data.column4}</td>
      <td>{data.column5}</td>
    </tr>
  );
};

const FeedHistoryDetails = () => {
  const [data, setData] = useState([]);
  const [comments, setComments] = useState([]);
  const params = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (params?.id) {
      get(`/admin/feed/detail/${params?.id}`)
        .then((response) => {
          console.log(response.data.feedData);
          setData(response.data.feedData);
        })
        .catch((error) => console.error(error));
      get(`/admin/feed/comment/${params?.id}`)
        .then((response) => {
          console.log(response?.data?.dataList);
          setComments(response?.data?.dataList);
        })
        .catch((error) => console.error(error));
    }
  }, [params?.id]);

  const updateComment = (id) => {
    put("/admin/feed/updateComment", {
      comment_id: id,
    })
      .then((res) => console.log(res))
      .catch((error) => console.error(error));
  };

  const TableFourRow = ({ data }) => {
    return (
      <tr>
        <td>{data?.writer?.nick_name}</td>
        <td style={{ textAlign: "start", paddingLeft: 10 }}>
          {data?.comment_desc}
        </td>
        <td>{data?.comment_dtm.slice(0, 10)}</td>
        <td>
          <select
            onChange={() => updateComment(data?.comment_id)}
            className="select"
          >
            <option value="Yes">Y</option>
            <option value="No">N</option>
          </select>
        </td>
      </tr>
    );
  };

  const referance = data?.feed_thumb_url?.toString().split("/");
  const file = referance?.[referance?.length - 1];

  return (
    <div style={styles.container}>
      <h2 className="com_pg_tit" style={styles.pageTitle}>
        피드관리
      </h2>
      <hr />
      <h2 className="pg_tit" style={styles.sectionTitle}>
        ◆ 피드 내역 &gt; 상세
      </h2>
      <h5>- 피드 등록 정보</h5>
      <table className="com_board_list type_row">
        <colgroup>
          <col width="10%" />
          <col />
        </colgroup>
        <tr>
          <th>제목</th>
          <td>{data?.feed_title}</td>
        </tr>
        <tr>
          <th>태그</th>
          <td>{data?.feed_tag?.map((item) => item)?.join("|")}</td>
        </tr>
        <tr>
          <th>썸네일 이미지</th>
          <td>
            <div style={styles.box}>
              <img
                alt="thumbnail"
                src={data?.feed_thumb_url}
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          </td>
        </tr>
        <tr>
          <th>영상 첨부</th>
          <td>
            <div style={styles.box}>
              <video
                controls
                src={data?.feed_url}
                type="video/mp4"
                style={{ width: "100%", height: "100%" }}
              ></video>
            </div>
          </td>
        </tr>
        <tr>
          <th>동영상 링크</th>
          <td>{data?.feed_url}</td>
        </tr>
        <tr>
          <th>호텔명</th>
          <td>{data?.hotel_name_kr}</td>
        </tr>
        <tr>
          <th>호텔 주소</th>
          <td>{data?.hotel_addr1_kr}</td>
        </tr>
        <tr>
          <th>참고파일</th>
          <td>{file}</td>
        </tr>
        <tr>
          <th>찜하기 수</th>
          <td>{data?.feed_heart_cnt}</td>
        </tr>
        <tr>
          <th>노출상태</th>
          <td>
            <select value={"전부노출"} className="select ws">
              <option value="전부노출">전부노출</option>
            </select>
          </td>
        </tr>
      </table>

      <div style={{ width: "100%" }}>
        <div style={{ display: "flex" }}>
          <h5>- 예약 연결 내역</h5>
          <h6 className="com_red_txt" style={{ marginLeft: "2px" }}>
            {" "}
            (예약 연결 수 : 1)
          </h6>
        </div>
        <table
          className="com_board_list"
          style={{ border: "1px solid #D3D3D3" }}
        >
          <thead>
            <tr>
              <th>번호</th>
              <th>아이디(이메일)</th>
              <th>국문명</th>
              <th>영문명</th>
              <th>예약일자</th>
            </tr>
          </thead>
          <tbody>
            {singleTable.map((data, index) => (
              <TableRow key={index} data={data} />
            ))}
          </tbody>
        </table>

        <Paging />
      </div>

      <div style={{ width: "100%" }}>
        <div style={{ display: "flex", marginTop: "20px" }}>
          <h5>- 피드 댓글 내역</h5>
          <h6 className="com_red_txt" style={{ marginLeft: "2px" }}>
            {" "}
            (댓글 수 : 4)
          </h6>
        </div>
        <table
          className="com_board_list"
          style={{ border: "1px solid #D3D3D3" }}
        >
          <thead>
            <tr>
              <th>작성자</th>
              <th>내용</th>
              <th>작성일자</th>
              <th>노출여부</th>
            </tr>
          </thead>
          <tbody>
            {comments.map((data, index) => (
              <TableFourRow key={index} data={data} />
            ))}
          </tbody>
        </table>

        <Paging />

        <div style={styles.buttonContainer}>
          <button
            onClick={() => navigate("/Feed/History")}
            style={styles.button}
            className="btn"
          >
            목록
          </button>
        </div>
      </div>
    </div>
  );
};

export default FeedHistoryDetails;

const styles = {
  container: {
    width: "100%",
  },
  pageTitle: {
    fontSize: "24px",
    fontWeight: "bold",
  },
  sectionTitle: {
    fontSize: "20px",
    marginTop: "20px",
    marginBottom: "1%",
  },
  formContainer: {
    width: "100%",
    border: "1px solid black",
  },
  row: {
    display: "flex",
  },
  labelColumn: {
    width: "12%",
    borderRight: "1px solid black",
    backgroundColor: "#F2F2F2",
    fontWeight: "bold",
    paddingLeft: 10,
  },
  inputColumn: {
    width: "88%",
    padding: 10,
  },
  box: {
    width: "15%",
    height: 110,
    backgroundColor: "#A5A5A5",
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 20,
  },
  dropDown: {
    width: "70%",
    height: "80%",
    borderRadius: "0px",
    border: "1px solid #808080",
    marginLeft: "5px",
    color: "#808080",
  },
  buttonContainer: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    marginTop: 25,
  },
  button: {
    backgroundColor: "#7F7F7F",
    borderRadius: 0,
    width: "8%",
    border: "1.5px solid #BFBFBF",
  },
};
