/* eslint-disable */
import React, { useState, useCallback } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { gnbData } from "../../utils/Constants/routesContants";
import SideMenu from "./SideMenu";
//import Logo from "../assets/img/logo.png";
function Header() {
  const pathName = useLocation().pathname;
  const [isGnbActive, setIsGnbActive] = useState(false);
  const [gnbIdx, setGnbIdx] = useState(0);
  //상단 Gnb
  const navigate = useNavigate();
  React.useEffect(() => {
    const admin = sessionStorage.getItem("admin_id");
    console.log(pathName);
    if (!admin) {
      if (pathName !== "/Login") {
        alert("로그인 후 이용해주세요.");
        navigate("/Login");
      }
    }
  }, []);

  const handleAuthentication = (action) => {
    const getToken = localStorage.getItem("accessToken");
    const token = JSON.parse(getToken);
    if (action === "logIn") {
      if (token) {
        return;
      }
    } else {
      localStorage.removeItem("accessToken");
      navigate("/Login");
    }
  };

  const renderGnb = useCallback(() => {
    return gnbData.map((v, idx) => {
      return (
        <NavLink
          to={v.link}
          key={v.title + "-" + idx}
          onClick={() => {
            setIsGnbActive(false);
            setGnbIdx(idx + 1);
          }}
          className={`link ${
            isGnbActive || gnbIdx === idx + 1 ? "active" : ""
          }`}
        >
          {v.title}
        </NavLink>
      );
    });
  }, [gnbIdx]);

  return (
    <header
      className={`head  ${pathName.includes("/Login") ? `isHideGnb` : ``}`}
    >
      <section className="gnb_wrap">
        <div className="com_center_wrap">
          <section className="gnb_list">{renderGnb()}</section>
          <section className="menu_list">
            <NavLink
              to="/"
              className="link"
              onClick={() => {
                setGnbIdx(0);
              }}
            >
              관리자 정보
            </NavLink>
            <NavLink
              to="/"
              className="link"
              onClick={() => {
                setGnbIdx(0);
              }}
            >
              웹사이트이동
            </NavLink>
            <p
              className="link"
              style={{ cursor: "pointer" }}
              onClick={() => handleAuthentication("logOut")}
            >
              로그아웃
            </p>
            {/* <p
              className="link"
              style={{ cursor: "pointer" }}
              onClick={() => handleAuthentication("logIn")}
            >
              로그인
            </p> */}
          </section>
        </div>
      </section>
      <SideMenu setGnbIdx={setGnbIdx} />
    </header>
  );
}

export default React.memo(Header);
