import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import "../assets/scss/styles.scss";
import RouterFeed from "./router.feed";

const RouterSetting = React.lazy(() => import("./router.setting"));
const RouterHotel = React.lazy(() => import("./router.hotel"));
const RouterBoard = React.lazy(() => import("./router.board"));
const RouterProduct = React.lazy(() => import("./router.product"));
const RouterMember = React.lazy(() => import("./router.member"));
const RouterReserve = React.lazy(() => import("./router.reserve"));
const RouterTrip = React.lazy(() => import("./router.trip"));

const Dashboard = React.lazy(() => import("../views/Dashboard"));
const About = React.lazy(() => import("../views/About"));
const Login = React.lazy(() => import("../views/page/member/Login"));

function Routers() {
  const pathName = useLocation().pathname;
  return (
    <div className={`${pathName.includes("/Login") ? `` : `com_container`}`}>
      <Routes>
        <Route exact path="/" element={<Dashboard />} />
        <Route exact path="/Login" element={<Login />} />
        <Route exact path="/Setting/*" element={<RouterSetting />} />
        <Route exact path="/Hotel/*" element={<RouterHotel />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/dashboard" element={<Dashboard />} />
        <Route exact path="/Product/*" element={<RouterProduct />} />
        <Route exact path="/Board/*" element={<RouterBoard />} />
        <Route exact path="/Member/*" element={<RouterMember />} />
        <Route exact path="/Reserve/*" element={<RouterReserve />} />
        <Route exact path="/Feed/*" element={<RouterFeed />} />
        <Route exact path="/Trip/*" element={<RouterTrip />} />
      </Routes>
    </div>
  );
}

export default Routers;
