export const data = [
  {
    id: 1,
    title: "축제",
    year: "N",
  },
  {
    id: 2,
    title: "테라스",
    year: "Y",
  },
  {
    id: 3,
    title: "호캉스",
    year: "Y",
  },
  {
    id: 4,
    title: "가족여행",
    year: "N",
  },
  {
    id: 5,
    title: "연인",
    year: "Y",
  },
];
export const columns = [
  {
    name: "번호",
    selector: (row) => row.id,
    width: "12%",
    id: "col-1",
    style: {
      borderRight: "1px solid #D3D3D3",
      borderLeft: "1px solid #D3D3D3",

      justifyContent: "center",
    },
  },
  {
    name: "태그명",
    selector: (row) => row.title,
    width: "64%",
    id: "col-2",
    style: {
      borderRight: "1px solid #D3D3D3",
    },
  },
  {
    name: "노출여부",
    selector: (row) => row.year,
    width: "8%",
    id: "col-3",
    style: {
      borderRight: "1px solid #D3D3D3",
      justifyContent: "center",
    },
  },
  {
    name: "관리",
    id: "col-4",
    width: "12%",
    cell: () => (
      <div
        style={{
          width: "100%",
          textAlign: "center",
        }}
      >
        <button
          style={{
            backgroundColor: "#BFBFBF",
            color: "#fff",
            width: "60px",
          }}
          type="button"
          class="btn"
        >
          상 세
        </button>
      </div>
    ),
  },
];

export const tableData = [
  {
    column1: 1,
    column2: "피드 제목이 나오는 영역 입니다.",
    column3: "그랜드 하얏트 서울",
    column4: "김와플",
    column6: "abc123@naver.com",
    column7: "Kim waffle",
    column8: "10",
    column9: "2023.03.01",
    column10: "전부노출",
  },
  {
    column1: 2,
    column2: "피드 제목이 나오는 영역 입니다.",
    column3: "그랜드 하얏트 서울",
    column4: "김와플",
    column6: "abc123@naver.com",
    column7: "Kim waffle",
    column8: "5",
    column9: "2023.03.01",
    column10: "등록자만",
  },
  {
    column1: 3,
    column2: "피드 제목이 나오는 영역 입니다.",
    column3: "그랜드 하얏트 서울",
    column4: "김와플",
    column6: "abc123@naver.com",
    column7: "Kim waffle",
    column8: "2",
    column9: "2023.03.01",
    column10: "전부노출",
  },
  {
    column1: 4,
    column2: "피드 제목이 나오는 영역 입니다.",
    column3: "그랜드 하얏트 서울",
    column4: "김와플",
    column6: "abc123@naver.com",
    column7: "Kim waffle",
    column8: "3",
    column9: "2023.03.01",
    column10: "등록자만",
  },
  {
    column1: 5,
    column2: "피드 제목이 나오는 영역 입니다.",
    column3: "그랜드 하얏트 서울",
    column4: "김와플",
    column6: "abc123@naver.com",
    column7: "Kim waffle",
    column8: "0",
    column9: "2023.03.01",
    column10: "전부노출",
  },
];

export const singleTable = [
  {
    column1: 1,
    column2: "abc123@naver.com",
    column3: "김와플",
    column4: "Kim waffle",
    column5: "2023.03.01",
  },
];

export const feedComments = [
  {
    column1: "김와플",
    column2: "와 정말 가고 싶어요!",
    column3: "2023.03.01 10:10:10",
    column4: "Y",
  },
  {
    column1: "ㄴ정와플",
    column2: "캬~",
    column3: "2023.03.01 10:10:10",
    column4: "Y",
  },
  {
    column1: "ㄴ이와플",
    column2: "저도 가고 싶네요 ㅜ",
    column3: "2023.03.01 10:10:10",
    column4: "Y",
  },
  {
    column1: "이와플",
    column2: "좀더 좋은 객실도 있을까요? 자녀 3명정도 같이 갈만한 객실요!",
    column3: "2023.03.01 10:10:10",
    column4: "Y",
  },
];
