import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Paging from "../../component/Paging";
import { del, get } from "../../../services/apiHelper";

const FeedManagement = () => {
  const navigate = useNavigate();
  const [checkboxes, setCheckboxes] = useState({});
  const [isRegion, setIsRegion] = useState("region");
  const [tableData, setTabledata] = useState([]);

  const handleCheckboxChange = (id) => {
    setCheckboxes((prevCheckboxes) => ({
      ...prevCheckboxes,
      [id]: !prevCheckboxes[id],
    }));
  };

  const handleSelectAll = (isChecked) => {
    const updatedCheckboxes = {};
    tableData.forEach((data) => {
      updatedCheckboxes[data.tag_target_seq] = isChecked;
    });
    setCheckboxes(updatedCheckboxes);
  };

  const DeleteItems = () => {
    const checkedTagSeqs = [];
    tableData.forEach((data) => {
      if (checkboxes[data.tag_target_seq]) {
        checkedTagSeqs.push({ tag_target_seq: data.tag_target_seq });
      }
    });
    del("/admin/feedTag/delete", {
      datas: checkedTagSeqs,
    })
      .then((response) => response.messageCode === 200 && getItems())
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    getItems();
  }, []);
  const getItems = () => {
    get("/admin/feedTag/select")
      .then((response) => setTabledata(response.data.dataList))
      .catch((error) => console.error(error));
  };
  return (
    <main>
      <div>
        <div className="com_pg_tit">피드관리</div>
        <hr />
        <div className="pg_tit"> ◆ 피드 태그 관리</div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <button
              style={isRegion ? { color: "red" } : {}}
              className="com_btn"
              onClick={() => setIsRegion(true)}
            >
              지역
            </button>
            <span style={{ margin: "0px 15px" }}>|</span>
            <button
              style={!isRegion ? { color: "red" } : {}}
              className="com_btn"
              onClick={() => setIsRegion(false)}
            >
              테마{" "}
            </button>
          </div>
          <div>
            <button onClick={DeleteItems} className="com_btn red">
              삭제
            </button>
            <button
              onClick={() => navigate("/Feed/TagRegistration")}
              className="com_btn blue"
            >
              등록하기
            </button>
          </div>
        </div>
      </div>

      <div className="com_pg_content">
        <table className="com_board_list">
          <thead>
            <tr>
              <th style={{ width: "5%" }}>
                <div className="check">
                  <input
                    type="checkbox"
                    id="chk01"
                    className="com_chk"
                    onChange={(event) => handleSelectAll(event.target.checked)}
                  />
                  <label htmlFor="chk01" />
                </div>
              </th>
              <th style={{ width: "5%" }}>번호</th>
              <th style={{ width: "70%" }}>태그명</th>
              <th style={{ width: "10%" }}>순서</th>
              <th style={{ width: "10%" }}>노출여부</th>
              <th style={{ width: "10%" }}>관리</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((item, index) => (
              <tr key={item.tag_target_seq}>
                <td>
                  <div className="check">
                    <input
                      type="checkbox"
                      id={`chk_${item.tag_target_seq}`}
                      className="com_chk"
                      checked={checkboxes[item.tag_target_seq] || false}
                      onChange={() => handleCheckboxChange(item.tag_target_seq)}
                    />
                    <label htmlFor={`chk_${item.tag_target_seq}`} />
                  </div>
                </td>
                <td>{index + 1}</td>
                <td style={{ textAlign: "left" }}>{item.tag_val}</td>
                <td>{item.tag_target_seq}</td>
                <td>{item.use_YN}</td>
                <td>
                  <button
                    onClick={() =>
                      navigate(`/Feed/TagDetails/${item?.tag_target_seq}`)
                    }
                    className="com_btn gray"
                  >
                    상세
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Paging />
    </main>
  );
};

export default FeedManagement;
