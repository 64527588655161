import React from "react";

const ExchangeRate = () => {
  return (
    <div>
      <div>
        <div className="com_pg_tit">피드관리</div>
        <hr />
        <div className="pg_tit"> ◆ 주변 호텔 더보기 반경</div>
      </div>
      <div>
        <table className="com_board_list type_row">
          <colgroup>
            <col width="10%" />
            <col />
          </colgroup>
          <tbody>
            <tr>
              <th>반경 수정</th>
              <td>
                <input
                  type="text"
                  className="input"
                  style={{ width: "auto" }}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="com_btn_wrap center">
        <button className="com_btn gray">수정</button>
      </div>
    </div>
  );
};

export default ExchangeRate;
