import React, { useEffect, useState } from "react";
import Paging from "../../component/Paging";
import { useNavigate } from "react-router-dom";
import { get } from "../../../services/apiHelper";
const TableRow = ({ data, id }) => {
  const navigate = useNavigate();
  return (
    <tr>
      <td>{id + 1}</td>
      <td>{data?.feed_title}</td>
      <td>{data?.hotel_name_kr}</td>
      <td>{data?.user_email}</td>
      <td>{data?.user_name}</td>
      <td>
        {data?.first_name} {data?.last_name}
      </td>
      <td>0</td>
      <td>{data?.feed_reg_dtm.slice(0, 10)}</td>
      <td>전부노출</td>
      <td>
        <button
          className="com_btn gray"
          onClick={() => navigate(`/Feed/History/Details/${data?.feed_seq}`)}
        >
          상세
        </button>
      </td>
    </tr>
  );
};

const FeedHistory = () => {
  const [startDate, setStartDate] = useState("");
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    get("/admin/feed/list?limit=10&offset=10")
      .then((response) => setTableData(response.data.dataList))
      .catch((error) => console.error(error));
  }, []);
  console.log(tableData);

  const SearchSection = () => (
    <div className="com_pg_content">
      <div className="com_board_list__top">
        <div className="com_date_select">
          <span className="tit">등록일자 : </span>
          <input
            type="date"
            className="com_datepicker"
            placeholder="2022. 01. 01"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
          <span className="divider"> ~ </span>
          <input
            type="date"
            className="com_datepicker"
            placeholder="2022. 01. 01"
          />
        </div>
        <div className="com_input_wrap flex_row">
          <span className="tit">노출상태 :</span>
          <select className="select">
            <option value="entire">전체</option>
            <option value="allExposed">전부노출</option>
            <option value="use3Select">사용 3종</option>
          </select>
          <select className="select">
            <option value="entire">전체</option>
            <option value="title">제목</option>
            <option value="hotelName">호텔명</option>
            <option value="IdEmail">아이디(이메일)</option>
            <option value="KoreanCivilization">국문명</option>
            <option value="EnglishName">영문명 </option>
          </select>
        </div>
        <div className="com_board_search end">
          <article className="search">
            <input type="search" className="input s type_search" />
            <button className="com_btn gray" style={{ width: "70px" }}>
              조회
            </button>
          </article>
        </div>
      </div>
    </div>
  );
  return (
    <div style={styles.container}>
      <div className="com_pg_tit">피드관리</div>
      <hr />
      <div className="pg_tit">◆ 피드 내역</div>
      <div style={{ width: "100%", marginTop: "1%" }}>
        <SearchSection />
        <table
          className="com_board_list"
          style={{ border: "1px solid #D3D3D3" }}
        >
          <thead>
            <tr>
              <th>번호</th>
              <th>제목</th>
              <th>호텔명</th>
              <th>아이디(이메일)</th>
              <th>국문명</th>
              <th>영문명</th>
              <th>예약 연결 수</th>
              <th>등록일자</th>
              <th>노출상태</th>
              <th>관리</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((data, index) => (
              <TableRow key={index} data={data} id={index} />
            ))}
          </tbody>
        </table>
        <Paging />
      </div>
    </div>
  );
};

export default FeedHistory;

const styles = {
  container: {
    width: "100%",
  },
};
