import React, { useEffect, useState } from "react";
import { del, get, post, put } from "../../../services/apiHelper";
import { useNavigate, useParams } from "react-router-dom";
function FeedTagRegistration() {
  const [tag, setTag] = useState("");
  const [useYN, setUseYN] = useState("Y");
  const [tagNumber, setTagNumber] = useState("");
  const navigate = useNavigate();
  const params = useParams();

  const insertData = () => {
    post("/admin/feedTag/insert", {
      tag_no: tagNumber,
      tag_val: tag,
      use_YN: useYN,
    })
      .then(
        (response) =>
          response.messageCode === 200 && navigate("/Feed/Management")
      )
      .catch((error) => console.error(error));
  };

  const updateData = () => {
    put("/admin/feedTag/update", {
      tag_target_seq: params?.id,
      tag_no: tagNumber,
      tag_val: tag,
      use_YN: useYN,
    }).then(
      (response) => response.messageCode === 200 && navigate("/Feed/Management")
    );
  };

  const deleteRecord = () => {
    del("/admin/feedTag/delete", {
      datas: [{ tag_target_seq: params?.id }],
    })
      .then(
        (response) =>
          response.messageCode === 200 && navigate("/Feed/Management")
      )
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    if (params?.id) {
      get(`/admin/feedTag/detail/${params?.id}`)
        .then((res) => {
          console.log(res?.data?.boardData[0]);
          setTag(res?.data?.boardData[0]?.tag_val);
          setUseYN(res?.data?.boardData[0]?.use_YN);
          setTagNumber(res?.data?.boardData[0]?.tag_no);
        })
        .catch((error) => console.error(error));
    }
  }, [params?.id]);
  return (
    <main>
      <div>
        <div className="com_pg_tit">피드관리</div>
        <hr />
        <div className="pg_tit"> ◆ 피드 태그 관리 &gt; 등록/상세</div>
      </div>

      <table className="com_board_list type_row">
        <tr>
          <th style={{ width: "15%" }}>태그명</th>
          <td>
            <input
              value={tag}
              onChange={(e) => setTag(e.target.value)}
              style={{ width: "100%" }}
              type="text"
              className="input"
            />
          </td>
        </tr>
        <tr>
          <th style={{ width: "15%" }}>태그 번호</th>
          <td>
            <input
              value={tagNumber}
              onChange={(e) => setTagNumber(e.target.value)}
              style={{ width: "100%" }}
              type="number"
              className="input"
            />
          </td>
        </tr>
        <tr>
          <th>노출여부</th>
          <td>
            <select
              value={useYN}
              onChange={(e) => setUseYN(e.target.value)}
              style={{ width: "auto" }}
              id="exposure"
              name="exposure"
            >
              <option value="Y">Y</option>
              <option value="N">N</option>
            </select>
          </td>
        </tr>
      </table>

      <div className="com_btn_wrap center">
        <button
          onClick={params?.id ? () => updateData() : () => insertData()}
          className={params?.id ? "com_btn lightGray s" : "com_btn blue s"}
        >
         {params?.id ?  '수정' : '등록'}
        </button>
        <button
          onClick={() => navigate("/Feed/Management")}
          className="com_btn gray s"
        >
          목록
        </button>
        {params?.id && (
          <button onClick={deleteRecord} className="com_btn red s">
            삭제
          </button>
        )}
      </div>
    </main>
  );
}

export default FeedTagRegistration;
